import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-oklahoma-city-oklahoma.png'
import image0 from "../../images/cities/scale-model-of-bricktown-water-taxi-in-oklahoma-city-oklahoma.png"
import image1 from "../../images/cities/scale-model-of-uss-oklahoma-anchor-memorial-in-oklahoma-city-oklahoma.png"
import image2 from "../../images/cities/scale-model-of-milk-bottle-grocery-in-oklahoma-city-oklahoma.png"
import image3 from "../../images/cities/scale-model-of-greetings-from-oklahoma-city-in-oklahoma-city-oklahoma.png"
import image4 from "../../images/cities/scale-model-of-myriad-botanical-gardens-in-oklahoma-city-oklahoma.png"
import image5 from "../../images/cities/scale-model-of-oklahoma-city-zoo-in-oklahoma-city-oklahoma.png"
import image6 from "../../images/cities/scale-model-of-national-cowboy-&-western-heritage-museum-in-oklahoma-city-oklahoma.png"
import image7 from "../../images/cities/scale-model-of-american-banjo-museum-in-oklahoma-city-oklahoma.png"
import image8 from "../../images/cities/scale-model-of-oklahoma-city-national-memorial-&-museum-in-oklahoma-city-oklahoma.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Oklahoma City'
            state='Oklahoma'
            image={image}
            lat='35.4671'
            lon='-97.5137'
            attractions={ [{"name": "Bricktown Water Taxi", "vicinity": "111 S Mickey Mantle Dr, Oklahoma City", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 35.465041, "lng": -97.50935400000003}, {"name": "USS Oklahoma Anchor Memorial", "vicinity": "1320 N Broadway Ave, Oklahoma City", "types": ["point_of_interest", "establishment"], "lat": 35.4816245, "lng": -97.51376929999998}, {"name": "Milk Bottle Grocery", "vicinity": "2426 N Classen Blvd, Oklahoma City", "types": ["point_of_interest", "establishment"], "lat": 35.494198, "lng": -97.53215290000003}, {"name": "Greetings from Oklahoma City", "vicinity": "320 NW 11th St, Oklahoma City", "types": ["point_of_interest", "establishment"], "lat": 35.4796809, "lng": -97.51845079999998}, {"name": "Myriad Botanical Gardens", "vicinity": "301 W Reno Ave, Oklahoma City", "types": ["park", "point_of_interest", "establishment"], "lat": 35.46533110000001, "lng": -97.51790729999999}, {"name": "Oklahoma City Zoo", "vicinity": "2101 NE 50th St, Oklahoma City", "types": ["zoo", "park", "point_of_interest", "establishment"], "lat": 35.5235472, "lng": -97.47248939999997}, {"name": "National Cowboy & Western Heritage Museum", "vicinity": "1700 NE 63rd St, Oklahoma City", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.535713, "lng": -97.48334899999998}, {"name": "American Banjo Museum", "vicinity": "9 E Sheridan Ave, Oklahoma City", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.466806, "lng": -97.51149799999996}, {"name": "Oklahoma City National Memorial & Museum", "vicinity": "620 N Harvey Ave, Oklahoma City", "types": ["museum", "park", "point_of_interest", "establishment"], "lat": 35.4731457, "lng": -97.5170594}] }
            attractionImages={ {"Bricktown Water Taxi":image0,"USS Oklahoma Anchor Memorial":image1,"Milk Bottle Grocery":image2,"Greetings from Oklahoma City":image3,"Myriad Botanical Gardens":image4,"Oklahoma City Zoo":image5,"National Cowboy & Western Heritage Museum":image6,"American Banjo Museum":image7,"Oklahoma City National Memorial & Museum":image8,} }
       />);
  }
}